
<template>
  <section
    id="companies"
  >
    <vue-headful
      :title="companies.companies_we_represent"
      :description="companies.companies_we_represent"
    />
    <core-jumbotron-mobile />
    <heading>
      {{ $t("companies.companies_we_represent") }}
    </heading>
    <v-container>
      <v-row
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-col
          cols="12"

          class="text-left"
        >
          <p class="title">
            {{ $t("companies.represent_variety") }}
          </p>
          <p class="body-1">
            {{ $t("companies.same_attributes") }}
          </p>
          <ul class="body-1 mb-5">
            <li>{{ $t("companies.uniqueness_market") }}</li>
            <li>{{ $t("companies.embrace_strong_culture") }}</li>
            <li>{{ $t("companies.products_fit_european_market") }}</li>
            <li>{{ $t("companies.established_solid_reputation") }}</li>
            <li>{{ $t("companies.dedicated_serving_europe") }}</li>
          </ul>

          <p class="body-1 font-weight-bold">
            {{ $t("companies.learn_more") }}
          </p>
        </v-col>
      </v-row>

      <v-row
        align="center"
        justify="center"
        class="fill-height"
      >
        <router-link
          v-for="(company, i) in companies"
          :key="i"
          class="col-md-3 col-12"
          :to="{ name: 'company', params: { lang:$i18n.locale,company: i }}"
        >
          <v-img
            v-if="!company.disabled"
            :src="company.logo"
            class=" elevation-1 "
            aspect-ratio="2"
            shaped
            outlined
            light
          />
        </router-link>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import i18n from '../i18n'

  export default {
    metaInfo: {
      title: i18n.t('menu.companies_we_represent'),
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },
    components: {
      Heading: () => import('@/components/Heading'),
      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },

    data: () => ({

      companies: null,
    }),
    watch: {
      // call again the method if the route changes
      $route: 'fetchData',

    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData()
    },
    methods: {
      fetchData () {
        this.error = this.post = null
        this.loading = true
        // fetch company info
        fetch('/api/' + i18n.locale + '/companies.json')
          .then(r => r.json())
          .then(json => {
                  this.loading = false

                  this.companies = json
                },
                response => {
                  console.log('Error loading json:', response)
                })
      },
    },
  }
</script>

<style lang="sass">
  #projects
      .v-slide-group__content
        justify-content: center
      .v-list-item__title
        text-decoration: none
      .v-list-item__subtitle
        text-decoration: none
</style>
